.card-footer .get-started-btn {
    display: inline-block;
    padding: 10px 40px 11px 40px;
    border-radius: 4px;
    color: #0763af;
    transition: none;
    font-size: 14px;
    font-weight: 600;

    border: 2px solid #f2f3f5;
    background: #f2f3f5;
    text-decoration: none;
  }

  .card-footer h5 {
    color: #fff;
  }

  #subheading{
    margin-bottom: 2rem;
  }

  .card-footer:hover .get-started-btn {
    background-color: #0763af;
    color: white;
    border: white 2px solid;
  }

  .card-footer .get-started-btn:hover::after {

    background-color: #0763af;

  }

  .card {
    border-top: #0763af 4px solid;
  }

  .card-body h3 {
    font-weight: bold;
  }

  .info-box {
    color: #0b2341;
    text-align: center;
    box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
    padding: 20px 0 30px 0;
    background: #fff;
    padding-bottom: 15;
  }

  .dot {
    height: 30px;
    width: 30px;
    background-color: #0763af;
    border-radius: 50%;
    display: inline-block;
    color: white;
    text-align: center;
    align-items: top;
  }

  .info-box h6 {
    color: #0763af;
    padding-top: 4%;
  }

  .text h3 {
    color: white;
  }


  .email-form {
    width: 100%;
  }

  input[type=text],
  select,
  textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 16px;
    resize: vertical;
  }

  input[type=submit] {
    background-color: #04AA6D;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  input[type=submit]:hover {
    background-color: #45a049;
  }

  #contact .emailform .row {
    border-radius: 5px;
    background-color: #fff;
    padding: 20px;
  }

  .button {
    background-color: #087eca;
    border: 1px solid #fff;
    color: #fff;
    border-radius: 5px;

    color: white;
    padding: 10px 30px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
  }
  @media (max-width:768px) {
    
    #career-text {
      margin-top: -2rem;
    }
  }


  .arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px; /* Adjust width as necessary */
  }

  .arrow i {
    font-size: 28px; /* Adjust arrow size as necessary */
    color: #087eca; /* Adjust color as necessary */
  }

  .info-box {
    position: relative;
    width: 100%;
    text-align: center;
  }

  #career-row {
    display: flex;
    justify-content: space-between;
  }

  #apply{
    cursor: pointer;
  }