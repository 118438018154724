.sechead{
    color:  #087eca;
    font-size: 1.9rem;
}
#submit {
    background-color: #087eca;
    border: 1px solid #fff;
    color: #fff;
    border-radius: 5px;
    color: white;
    padding: 10px 30px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
}