.portfolio #portfolio-flters {
    padding: 0;
    margin: 0 auto px auto;
    list-style: none;
    text-align: center;
    border-radius: 50px;

}

.pageheading{
    color: #0763af;
}

.portfolio-details {
    background-color: rgb(255, 255, 255);
    margin-bottom: 3rem;
}

.portfolio {
    background-color: #0763af;
}

.portfolio #portfolio-flters li {
    cursor: pointer;
    display: inline-block;
    padding: 10px 15px;
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
    text-transform: uppercase;
    color: #fcfdff;
    margin-bottom: 5px;
    transition: all 0.3s ease-in-out;
}

.portfolio #portfolio-flters li:hover,
.portfolio #portfolio-flters li.filter-active {
    color: #0763af;
}

.portfolio #portfolio-flters li:last-child {
    margin-right: 0;
}

.descriptionHead{
    width: 23rem;
}

.slick-slide img {
    display: block;
    width: 90%;
}

.img{
    padding-left: 34%;
    height: 50%;
    width: 80%;
    box-shadow: #05101e; 
}

.portfolio-details .swiper-wrapper img {

    padding-left: 30%;
    height: 50%;
    width: 75%;

    box-shadow: #05101e;
}

.portfolio .portfolio-item {
    margin-bottom: 30px;
}

.portfolio .portfolio-item .portfolio-info {
    opacity: 0;
    position: absolute;
    left: 30px;
    right: 30px;
    bottom: 0;
    z-index: 3;
    width: 60%;
    transition: all ease-in-out 0.3s;
    background-color: white;
    padding: 15px;
    border: #05101e 2px solid;
    border-radius: 8%;

}

.portfolio-details-slider .swiper-wrapper {
    background-color: white;
}

.portfolio-details .portfolio-info {
    background-color: white;
}


.portfolio .portfolio-item .portfolio-info h4 {
    font-size: 18px;
    color: #fdfeff;
    font-weight: 600;
    color: #fafafa;
}

.portfolio .portfolio-item .portfolio-info p {
    color: #f1f3f5;
    font-size: 14px;
    margin-bottom: 0;
    text-align: left;
}

.portfolio .portfolio-item .portfolio-info .preview-link,
.portfolio .portfolio-item .portfolio-info .details-link {
    position: absolute;
    right: 40px;
    font-size: 24px;
    top: calc(50% - 18px);
    color: #fafcfd;
}

.portfolio .portfolio-item .portfolio-info .preview-link:hover,
.portfolio .portfolio-item .portfolio-info .details-link:hover {
    color: #fcfcfc;
}

.portfolio .portfolio-item .portfolio-info .details-link {
    right: 10px;
}

.portfolio .portfolio-item .portfolio-links {
    opacity: 0;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 3;
    position: absolute;
    transition: all ease-in-out 0.3s;
}

.portfolio .portfolio-item .portfolio-links a {
    color: #fff;
    margin: 0 2px;
    font-size: 28px;
    display: inline-block;
    transition: 0.3s;
}

.portfolio .portfolio-item .portfolio-links a:hover {
    color: #f59f8c;
}

.portfolio .portfolio-item:hover .portfolio-info {
    opacity: 1;
    bottom: 20px;

}

.port-card {
    width: 75%;
    height: 75%;

}

.port-card img {
    background-color: #ffffff;
}

.port-card img:hover {
    background-color: #9b9ea0;
    color: white;
}

.port-card .container {
    border: 1px #021e35;


}

.port-card img {
    width: 100%;
    border-radius: 5%;

}

.portfolio .portfolio-item .filter-app:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);

}

.port-card .container:hover::after {
    color: wheat;
}