.pricing .row {
    padding-top:0;
  }
  
  .pricing .box{
    border: #0763af 1px solid;
  }
  
  .pricing .box {
  
    margin-bottom: 10px;
    box-shadow: 0px 5px 90px 0px rgba(110, 123, 131, 0.1);
    background: #fff;
    text-align: center;
  }
  .pricing .box .price-img{
    padding-top: 5%;
    padding-bottom: 7%;   
  }
  .pricing .box .price-img:hover{
    border-radius: 10%;
  }
  
  .pricing .box p{
    margin: 20px;
  }
  .pricing .box:hover p {
    color: white;
  
  }
  .pricing .box:hover h3{
    color: whitesmoke;
  }
  .pricing .box:hover{
    background-color: #0763af;
    
  }
  
  .pricing h3 {
    font-weight: 300;
    
    
  }
  
  .pricing h3 {
    font-size: 35px;
    color: #0763af;
    
    font-family: "Open Sans", sans-serif;
   
  }
  
  
  .pricing h4 span {
    color: #bababa;
    font-size: 18px;
    display: block;
  }
  
  .pricing ul {
    padding: 0;
    list-style: none;
    color: #021e35;
    text-align: left;
    line-height: 20px;
  }
  
  .pricing ul li {
    padding-bottom: 12px;
  }
  
  
  
  .pricing ul .na {
    color: #ccc;
  }
  
  .pricing ul .na i {
    color: #ccc;
  }
  
  .pricing ul .na span {
    text-decoration: line-through;
  }
  .pricing .box .b1{
   border: #0763af;
   background-color: white;
   
  }
  .pricing .box:hover h3{
    color:#0763af
  }
  
  .pricing .get-started-btn {
    display: inline-block;
    padding: 10px 40px 11px 40px;
    border-radius: 4px;
    color: #f0f4f7;
    transition: none;
    font-size: 14px;
    font-weight: 600;
    transition: 0.3s;
    border: 2px solid #f2f3f5;
    background:#0763af;
  }
  .pricing  .box:hover .get-started-btn{
    background-color: whitesmoke;
    color: #0763af;
    border: #0763af 2px solid;
  }
  .pricing .get-started-btn:hover {
    
    color:whitesmoke
   
  }
  
  
  
  
  
  
  @media (max-width: 992px) {
    .pricing .box {
      max-width: 60%;
      margin: 0 auto 30px auto;
    }
  }
  
  @media (max-width: 767px) {
    .pricing .box {
      max-width: 80%;
      margin: 0 auto 30px auto;
    }
  }
  
  @media (max-width: 420px) {
    .pricing .box {
      max-width: 100%;
      margin: 0 auto 30px auto;
    }
  }