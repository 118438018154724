
/* Ensure chatbot container is hidden by default */
.chat-container {
    display: block; /* Hide the chat container initially */
    width: 400px;
    height: 520px;
    position: fixed;
    right: 20px;
    top: 35px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    z-index: 1000;
}

/* Fullscreen styling for the chat container */
.chat-container.fullscreen {
    display: block; /* Ensure it remains visible in fullscreen mode */
    width: 100vw;
    height: 100vh;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0;
    border-radius: 0;
    z-index: 10000;
}

/* Chatbot Header */
.chat-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
    background-color: #eaf7ff;
    border-bottom: 2px solid #29a0e5;
    /* border:  1px solid #29a0e5; */
    color: white;
}


.chatlogo {
    width: 104px;
    height: 25px;
}

.header-text {
    flex-grow: 1;
    margin-left: 23px;
    font-size: 14px;
    font-weight: bold;
    color: black;
}

.header-icons .icon-button {
    background: none;
    border: none;
    color: #29a0e5; /* Makes the icon white */
    font-size: 18px; /* Adjust size of the icon */
    cursor: pointer;
    margin-left: 5px;
    outline: none; /* Remove focus outline on click */
}



/* Chatbot Body */
.chat-body {
    padding: 15px;
    text-align: center;
}

/* Typing Indicator Style */
.typing-indicator {
    display: none; /* Hidden by default */
    font-size: 14px;
    color: #29a0e5;
    margin-top: 10px;
    text-align: left;
}

/* Chat Messages Styling */
.user-message, .bot-message {
    max-width: 70%;
    padding: 8px 12px;
    border-radius: 8px;
    margin: 5px 0;
    font-size: 14px;
    display: inline-block;
    word-wrap: break-word;
}

/* User Message (Right-Aligned) */
.user-message {
    background-color: #e0f7fa;
    color: #00796b;
    align-self: flex-end;
    margin-left: auto; /* Push to the right */
    text-align: right;
    border-top-right-radius: 0; /* Custom corner shape */
}

/* Bot Message (Left-Aligned) */
.bot-message {
    background-color: #f1f8e9;
    color: #33691e;
    align-self: flex-start;
    margin-right: auto; /* Push to the left */
    text-align: left;
    border-top-left-radius: 0; /* Custom corner shape */
}

/* Chatbox Section */
.chatbox {
    margin-top: 15px;
    padding: 10px;
    border-radius: 8px;
    max-height: 220px;
    overflow-y: auto; /* Add scrollbar when content exceeds height */
    display: flex;
    flex-direction: column; /* Stack messages vertically */
    gap: 10px; /* Add space between messages */
}


.welcome-message {
    font-size: 14px;
    color: #333;
    margin-bottom: 15px;
}

.instruction {
    font-size: 12px;
    color: #777;
    margin-bottom: 20px;
}

.button-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
}

.chat-button {
    padding: 8px 12px;
    font-size: 12px;
    color: white;
    background-color: #29a0e5;
    border: none;
    border-radius: 16px;
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    transition: background-color 0.2s ease;
}

.chat-button:hover {
    background-color: #238acb;
}

/* Chatbot Footer */

.footer-placement{
   position: relative;
   top: 10px;
   bottom: 10px;
}

.chat-footer {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    border-top: 1px solid #ddd;
    margin-top: 0rem; /* Ensures footer sticks to the bottom */
}



.input-box {
    flex-grow: 1;
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 20px;
    outline: none;
}

.send-button {
    margin-left: 10px;
    background-color: #29a0e5;
    border: none;
    color: white;
    padding: 10px;
    font-size: 16px;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    transition: background-color 0.2s ease;
}

.send-button:hover {
    background-color: #238acb;
}

/* Chatbot Toggle Button */
.chatbot-toggle {
    position: fixed;
    bottom: 13px;
    right: 10px;
    background-color: transparent;
    border: none;
    font-size: 20px;
    width: 8rem;
    height: 8rem;
    border-radius: 50%;
    cursor: pointer;
    z-index: 1001;
    transition: background-color 0.3s ease;
    padding: 0;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center; /* Center the maximize icon */
}

/* Default rotating background image */
.chatbot-toggle::before {
    content: '';
    position: absolute;
    top: 7px;
    left: 8px;
    width: 85%;
    height: 85%;
    background-image: url('../../../public/rmcchatbot\ 1.png'); /* Keep the background image */
    animation: rotateOuterImage 10s linear infinite;
    background-size: cover;
    background-position: center;
    z-index: 1;
    border-radius: 50%;
}




/* Keyframes for rotation animation */
@keyframes rotateOuterImage {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/* Chatbot Responsiveness */
@media (max-width: 768px) {
    .chat-container {
        width: 90%;
        height: 80%;
        right: 5%;
        top: 10%;
    }

    .chat-header,
    .chat-footer {
        padding: 8px 10px;
    }

    .header-text {
        font-size: 14px;
    }

    .chat-button {
        padding: 6px 10px;
        font-size: 10px;
    }

    .input-box {
        padding: 8px;
        font-size: 12px;
    }

    .send-button {
        padding: 8px;
        font-size: 14px;
    }

    .chatbot-toggle {
        width: 6rem;
        height: 6rem;
    }

    .chatbot-toggle i {
        font-size: 35px;
    }
}

@media (max-width: 480px) {
    .chat-container {
        width: 100%;
        height: 100%;
        right: 0;
        top: 0;
        border-radius: 0;
    }

    .chat-header,
    .chat-footer {
        padding: 6px 8px;
    }

    .header-text {
        font-size: 12px;
    }

    .chat-button {
        padding: 4px 8px;
        font-size: 10px;
    }

    .input-box {
        padding: 6px;
        font-size: 10px;
    }

    .send-button {
        padding: 6px;
        font-size: 12px;
    }

    .chatbot-toggle {
        width: 5rem;
        height: 5rem;
    }

    .chatbot-toggle i {
        font-size: 30px;
    }
}

.chatbot-image {
    width: 50%;
    height: 50%;
    position: relative;
    z-index: 2;
    object-fit: cover;
    margin-left: 10px;
}


/* Chatbot Toggle Button (Maximize State) */
.chatbot-toggle i {
    font-size: 30px; /* Adjust icon size */
    color: #29a0e5; /* Icon color */
}

.chatbot-toggle i:hover {
    color: #238acb; /* Slightly darker color on hover */
}



.user-message {
    background-color: #e0f7fa;
    padding: 8px 12px;
    border-radius: 8px;
    margin: 5px 0;
    text-align: left;
    color: #00796b;
    font-size: 14px;
  }
  
  .bot-message {
    background-color: #f1f8e9;
    padding: 8px 12px;
    border-radius: 8px;
    margin: 5px 0;
    text-align: left;
    color: #33691e;
    font-size: 14px;
  }
  
  .bot-message a {
    color: #388e3c;
    text-decoration: none;
    margin-left: 5px;
  }
  
  .bot-message a:hover {
    text-decoration: underline;
  }
  
  .message-time {
    font-size: 12px;
    color: #888;
    margin-top: 5px;
}

.user-time {
    text-align: right; /* Right align for user messages */
}

.bot-time {
    text-align: left; /* Left align for bot messages */
}

.login-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    overflow: hidden;
}

.login-form {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 300px;
    text-align: center;
    position: relative;
}

.login-form h2 {
    margin-bottom: 20px;
}

.login-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.login-button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.login-button:hover {
    background-color: #0056b3;
}

.close-login {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
    color: #333;
}
