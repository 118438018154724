/* Style for WhatsApp Icon Container */
.whatsapp-container {
    position: fixed;
    bottom: 20px; 
    left: 20px; /* Default position on the left */
    z-index: 1000; 
    animation: slideDown 1s ease-out forwards; /* Add animation here */
}

/* Animation keyframes */
@keyframes slideDown {
    0% {
        transform: translateY(-100%); /* Start from above the viewport */
    }
    100% {
        transform: translateY(0); /* End at its original position */
    }
}

/* Background for GIF */
.gifBg {
    background-color: rgba(255, 255, 255, 1); /* Set your desired background color (fully opaque white) */
    border-radius: 10px; /* Optional: to round the corners */
    display: inline-block; /* Ensures that the background wraps tightly around the content */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Optional: add a subtle shadow for better visibility */
}

/* Style for WhatsApp Icon */
.whatsapp-icon {
    width: 80px; /* Default size */
    height: 80px; 
    cursor: pointer;
}

/* Responsive Adjustments */
@media (max-width: 768px) { /* For tablets and smaller screens */
    .whatsapp-container {
        left: 10px; /* Adjust left margin for smaller screens */
        bottom: 15px; /* Adjust bottom margin */
    }
    .whatsapp-icon {
        width: 76px; /* Reduce icon size for smaller screens */
        height: 76px;
    }
}

@media (max-width: 480px) { /* For mobile devices */
    .whatsapp-container {
        left: 10px; /* Further reduce left margin for smaller screens */
        bottom: 20px; /* Further reduce bottom margin */
    }
    .whatsapp-icon {
        width: 65px; /* Further reduce icon size */
        height: 65px;
    }
}
