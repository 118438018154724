.cta-100 {

  padding-left: 0%;

}

.col-md-4 {
  padding-bottom: 10px;

}

.white {
  color: #fff !important;
}

.mt {
  float: left;
  margin-top: -20px;
  padding-top: 20px;
}

.bg-blue-ui {
  background-color: #fcfcfc !important;

}

.btn {
  color: #0068AD;
}

figure img {
  width: 200px;
}

#blogCarousel {
  padding-bottom: 100px;

}

.blog .carousel-indicators {
  left: 0;
  top: -50px;
  height: 50%;
}


.blog .carousel-indicators li {
  background: #708198;
  border-radius: 50%;
  width: 8px;
  height: 8px;
}

.blog .carousel-indicators .active {
  background: #0fc9af;
}




.item-carousel-blog-block {
  outline: medium none;
  padding: 0px;
}

.item-box-blog {
  border: 1px solid #dadada;
  text-align: center;
  z-index: 4;
  padding: 20px;
  margin: 15px;
  background-color: white;



}

#blogCarousel .item-box-blog:hover {
  transition: all 8s ease-out;
  box-shadow: 0px 4px 8px rgba(8, 8, 8, 0.2);
  border: 1px solid #cccccc;
  background-color: white;
  top: -5px;
  transition-duration: 0.2s;
}

#blogCarousel .item-box-blog:hover::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: -16px;
  right: -16px;

  height: 32px;
  width: 32px;
  border-radius: 32px;
  transform: scale(2);
  transform-origin: 50% 50%;


}

#blogCarousel.item-box-blog:hover::after {

  transform: scale(2.15);

}

.item-box-blog-image {
  position: relative;
  background-color: white;
  border-radius: 2%;



}

.item-box-blog-image figure img {
  height: 300px;
  width: 100%;
  border-radius: 5%;

}

.item-box-blog {
  /*background-image: linear-gradient(
      to left top,
     
      #0068AD,#55C3CC,  #2CB38E);*/
  background-image: white;
  /*border: 1px solid rgb(104, 103, 103);*/

}

.item-box-blog-date {
  position: absolute;
  z-index: 5;
  padding: 4px 20px;
  top: -20px;
  right: 8px;

}

.item-box-blog-heading h5 {
  color: #021e35;
  font-size: 20px;

}

.item-box-blog-date span {
  color: #fff;
  display: block;
  text-align: center;
  line-height: 1.2;
}

.item-box-blog-date span.mon {
  font-size: 18px;
}

.item-box-blog-date span.day {
  font-size: 16px;
}

.item-box-blog-body {
  padding: 10px;
  background: white;

  border: .1px solid rgb(170, 168, 168);
  border-bottom: .1px solid white;
  border-left: 1px solid white;
  border-right: 1px solid white;
}

.item-heading-blog h5 {
  margin: 0;
  line-height: 1;
  text-decoration: none;


}

.item-box-blog-heading a {
  text-decoration: none;
}

.item-box-blog-data p {
  font-size: 13px;
}

.item-box-blog-data p i {
  font-size: 12px;
}

.item-box-blog-text {
  max-height: 100px;
  overflow: hidden;
}

.mt-10 {
  float: left;
  margin-top: -10px;
  padding-top: 10px;
}

.btn.bg-blue-ui.white.read {
  cursor: pointer;


  margin-top: 10px;
}

.bg-blue-ui {
  background-color: #0068AD;
  color: white;
}

.btn.bg-blue-ui.white.read:hover {
  box-shadow: 0px 5px 15px inset #eeeff0;

}

.item-box-blog-body .get-started-btn {
  display: inline-block;
  padding: 10px 40px 11px 40px;
  border-radius: 4px;
  color: #0763af;
  transition: none;
  font-size: 14px;
  font-weight: 600;
  transition: 0.3s;
  border: 2px solid #f2f3f5;
  background: white;
  text-decoration: none;
  border: 2px solid #0763af;

}

.item-box-blog:hover .get-started-btn {
  background-color: #0763af;
  color: white;
  border: white 2px solid;
}

.item-box-blog .get-started-btn:hover::after {

  background-color: #0763af;

}