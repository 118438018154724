.section-title h1 {
  margin-top: 1.5rem;
  font-size: 32px;
  font-weight: 600;
  position: relative;
  color: #0693e3;
  text-transform: capitalize;
}

.testimonials {
  background-color: #e8f2fe;
  padding: 40px 0; /* Adjust padding as needed */
}

.testimonials .testimonial-wrap {
  padding-left: 50px;
  position: relative;
  max-width: 1000px; /* Example max-width for responsiveness */
  margin: 0 auto; /* Center align content */
}

.testimonials .testimonials-carousel,
.testimonials .testimonials-slider {
  overflow: hidden;
}

.testimonials .testimonial-item {
  box-sizing: border-box;
  padding: 30px;
  margin: 30px 0; /* Adjust margin as needed */
  min-height: 200px;
  box-shadow: 0px 0px 20px 0px rgba(11, 35, 65, 0.1);
  position: relative;
  background: #fff;
}

.testimonials .testimonial-item .testimonial-img {
  width: 90px;
  height: 90px; /* Ensure a square aspect ratio */
  border-radius: 50%;
  border: 6px solid #fff;
  position: absolute;
  left: -45px;
  top: 50%; /* Adjust positioning */
  transform: translateY(-50%);
}

.testimonials .testimonial-item h3 {
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0 5px 0;
  color: #111;
}

.testimonials .testimonial-item h4 {
  font-size: 14px;
  color: #999;
  margin: 0;
}

.testimonials .testimonial-item .quote-icon-left,
.testimonials .testimonial-item .quote-icon-right {
  color: #fdedea;
  font-size: 26px;
}

.testimonials .testimonial-item .quote-icon-left {
  position: absolute;
  left: 20px;
  top: 20px;
}

.testimonials .testimonial-item .quote-icon-right {
  position: absolute;
  right: 20px;
  bottom: 20px;
}

.testimonials .testimonial-item p {
  font-style: italic;
  margin: 15px 0;
}

.testimonials .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.testimonials .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  border: 1px solid #0763af;
}

.testimonials .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #0763af;
}

/* Media Queries for responsiveness */
@media (max-width: 768px) {
  .testimonials .testimonial-wrap {
      padding-left: 20px;
  }

  .testimonials .testimonial-item {
      padding: 20px;
  }

  .testimonials .testimonial-item .testimonial-img {
      width: 70px;
      height: 70px;
      left: -35px;
  }

  .testimonials .quote-icon-left {
      left: 10px;
  }

  .testimonials .quote-icon-right {
      right: 10px;
  }
}
