.map .container video{
    height: 100%;
    width:100%;
  
  }
  .map .section-title  h2 {
    font-size: 14px;
    font-weight: 500;
    padding: 0;
    line-height: 1px;
    margin: 0 0 5px 0;
    letter-spacing: 2px;
    text-transform: capitalize;
    color: #aaaaaa;
    font-family: "Poppins", sans-serif;
  }
  
  
  .map .section-title h1::before{
     content: "";
      width: 40px;
      height: 2px;
      background: #0763af;
      display: inline-block;
      margin-bottom: .8%;
      margin-right: 1%;
    
    
  }
  .map .section-title h1::after{
      content: '';
      width: 40px;
      height: 2px;
      background: #0763af;
      display: inline-block;
      margin-bottom: .8%;
      margin-left: 1%;
  }
  .map .section-title h1 {
    font-size: 30px;
      font-weight: 700;
     
      position: relative;
      color: #0763af;
      text-transform: capitalize;
      
  }