.bread  ul {
    list-style-type: none;
    margin: 0;
    padding-top: 5em;

  }
  
  .bread  li {
    display: inline-block;
    position: relative;
    padding-right: 2em;
    margin: 0;
   
   
  }
    .bread  li:after {
      content: '>';
      position: absolute;
      display: inline-block;
      right: 0;
      width: 2em;
      text-align: center;
    }

    .bread li:last-child:after {
      content: '';
    }
  
  .bread a {
    text-decoration: none;
    display: inline-block;
    color: #fff;
    font-size: .9rem;
  
    white-space: nowrap;
  }
    .bread a:hover {
      text-decoration: underline;
    }
  .collapsed{
    background-color: #0765aa;
  }